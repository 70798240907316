.bullet-point-div {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
}

.hero-about-me-button {
  display: none;
  width: fit-content;
}

@media screen and (min-width: 1024px) {
  .hero-about-me-button {
    display: flex;
    width: fit-content;
  }
}
